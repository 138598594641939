import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}

const Nav = () => {
  const [menuActive, setMenuActive] = React.useState(false)
  const [servicesActive, setServicesActive] = React.useState(false)
  const toggleMenu = () => {
    setMenuActive(!menuActive)  
    console.log('toggle')
  }
  const toggleServices = () => {
    setServicesActive(!servicesActive)
    console.log('toggle services')
  }
  const activateServices = () => {
    setServicesActive(true)
  }
  const deactivateServices = () => {
    setServicesActive(false)
  }

  return (
    <div className="nav-container w-full bg-white shadow py-4 px-2 md:px-0 animate__animated animate__slideInDown animate__faster">
      <nav className="flex justify-between container mx-auto">
        <div className="hidden md:block">
          <a href="/"><StaticImage src="../images/qoi-logo@2x.png" alt="QOI" width="180"/></a>
        </div>
        <div className="block md:hidden">
          <a href="/"><StaticImage src="../images/qoi-logo@2x.png" alt="QOI" width="120"/></a>
        </div>
        <div className="flex justify-end">
          <div id="mobileMenu" className={`${menuActive ? "active" : ""}`}>
            <a href="/#top" className="nav-item font-bold" onMouseOver={() => deactivateServices()}>
              Home
            </a>
            <a href="/#aboutus" className="nav-item "  onMouseOver={() => deactivateServices()}>
              About Us
            </a>
            <div className="relative nav-item active">
            <a href="#" className="" onClick={() => toggleServices()} onMouseOver={ () => activateServices() }>
              Services
            </a>
            <ul id="servicesMenu" className={`${servicesActive ? "active" : ""}`} >
              <a href="/home-technology" className="nav-item ">
               QOI Home Technology
              </a>
              <a href="/home-security" className="nav-item ">
               QOI Home Security
              </a>
              <a href="https://www.qoismartshades.com/" className="nav-item ">
                QOI Smart Shades
              </a>
              
            </ul>
            </div>

            <a href="/#products" className="nav-item " onMouseOver={() => deactivateServices()}>
              Products
            </a>
          </div>
          <div className="flex items-center justify-end">
            <a href="#contactus" className="nav-contact" onMouseOver={() => deactivateServices()}>
              Contact Us
            </a>
            <button id="menuButton" className={`${menuActive ? "" : "active"}`} onClick={() => toggleMenu()}  >
              <svg fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
            </button>
            <button id="closeButton" className={`${menuActive ? "active" : ""}`} onClick={() => toggleMenu()}>
            <svg fill="currentColor"  version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
	 width="28" height="28" viewBox="0 0 41.756 41.756" 
	 >
	<path d="M27.948,20.878L40.291,8.536c1.953-1.953,1.953-5.119,0-7.071c-1.951-1.952-5.119-1.952-7.07,0L20.878,13.809L8.535,1.465
		c-1.951-1.952-5.119-1.952-7.07,0c-1.953,1.953-1.953,5.119,0,7.071l12.342,12.342L1.465,33.22c-1.953,1.953-1.953,5.119,0,7.071
		C2.44,41.268,3.721,41.755,5,41.755c1.278,0,2.56-0.487,3.535-1.464l12.343-12.342l12.343,12.343
		c0.976,0.977,2.256,1.464,3.535,1.464s2.56-0.487,3.535-1.464c1.953-1.953,1.953-5.119,0-7.071L27.948,20.878z"/></svg>

            </button>
            
          </div>
        </div>

      </nav>
    </div>
  )
}

Nav.propTypes = {
  siteTitle: PropTypes.string,
}

Nav.defaultProps = {
  siteTitle: ``,
}

export default Nav
