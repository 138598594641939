import * as React from "react"

import ServicesContent from "../components/services-content"
import Seo from "../components/seo"

const ServicesPage = () => (
  <div>
    <Seo title="Services" />
    <ServicesContent/>
  </div>
)

export default ServicesPage
